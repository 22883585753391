$( document ).ready(function() {


// Example starter JavaScript for disabling form submissions if there are invalid fields
    /*(function() {
        'use strict';
        window.addEventListener('load', function() {
            // Fetch all the forms we want to apply custom Bootstrap validation styles to
            let forms = document.getElementsByClassName('needs-validation');
            // Loop over them and prevent submission
            Array.prototype.filter.call(forms, function(form) {
                form.addEventListener('submit', function(event) {
                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    else{
                        event.preventDefault();
                        event.stopPropagation();

                        if($("#cta").length)send_contact();
                        if($("#readers").length)send_reader();
                    }
                    form.classList.add('was-validated');
                }, false);
            });
        }, false);
    })();*/

    /*let send_contact = function() {
        let payload = {
            form_type: 'main',
            _token: $('#contacttoken').val(),
            name: $(".ime").val(),
            email: $(".email").val(),
            subject: $(".zadeva").val(),
            message:  $(".sporocilo").val(),
        };
        $.ajax({
            type: 'POST',
            url: "/contact",
            dataType: 'json',
            contentType: "application/json",
            data: JSON.stringify(payload),
            beforeSend: function(){
                $(".alert-success").addClass("d-none")
                $(".alert-danger").addClass("d-none")
            },
            success: function(data){
                if(data.code === 200){
                    $(".alert-success").removeClass("d-none");
                    $(".btn-form-submit").addClass("d-none");
                    triggerContactFormSubmittedEvent('General');
                }else{$(".alert-danger").removeClass("d-none")}
            },
            error: function(e) {
                $(".alert-danger").removeClass("d-none")
            }
        })
    };*/

    /*let send_reader = function() {
        let payload = {
            form_type: 'readers',
            _token: $('#readertoken').val(),
            name: $(".ime").val(),
            email: $(".email").val(),
            mobile_number: $(".telefonska").val(),
            message:  $(".sporocilo").val(),
        };
        $.ajax({
            type: 'POST',
            url: "/contact",
            dataType: 'json',
            contentType: "application/json",
            data: JSON.stringify(payload),
            beforeSend: function(){
                $(".alert-success").addClass("d-none")
                $(".alert-danger").addClass("d-none")
            },
            success: function(data){
                if(data.code === 200){
                    $(".alert-success").removeClass("d-none");
                    $(".btn-form-submit").addClass("d-none");
                    triggerContactFormSubmittedEvent('Reader');
                }else{$(".alert-danger").removeClass("d-none")}
            },
            error: function(e) {
                $(".alert-danger").removeClass("d-none")
            }
        })
    };*/
});

/*var recaptchaCallback = function(token) {
    if(token)$('.btn-submit').removeAttr('disabled');
    else $('.btn-submit').attr('disabled');
};*/


export function getUrlParameter(sParam) {
    let sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return null;
}
